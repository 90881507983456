.wrapper {
    display: flex;
    align-items: center;
    gap: 40px;
    &.row {
        flex-direction: row;
    }
    &.column {
        flex-direction: column;
    }
}