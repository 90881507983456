@import './../../styles/colors.module.scss';
@import './../../styles/mixins.module.scss';

.wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: $blue100;
    margin-bottom: 40px;

    @include for-phone-only {
        margin-bottom: 28px;
    }
}