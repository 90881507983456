@import './../../styles/mixins.module.scss';
@import './../../styles/colors.module.scss';

.menuIcon {
    color: $beige100;
    svg {
        width: 100%;
        height: 100%;
    }
    display: flex;
    cursor: pointer;
    height: 40px;
    width: 40px;
    padding: 8px;
    border-radius: 50%;
    background-color: $blue100;
    @include for-tablet-portrait-up {
        display: none;
    }
    position: sticky;
    top: 20px;
    right: 24px;
    float: right;
    z-index: 9999;
    &:focus {
        outline: none
    }
}

.menuContainer {
    cursor: default;
    background-color: $blue100;
    width: 100vw;
    z-index: 2000;
    position: sticky;
    top: 0;
    left: 0;
}

.linksContainer {
    @include flexColumn;
    color: $beige100;
    height: 100%;
    justify-content: center;
    clear: both;
}

.icon {
    color: $beige100;
    width: 100%;
    height: 100%;
}

.visible {
    padding: 0;
}