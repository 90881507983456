@import './../../styles/mixins.module.scss';

.wrapper {
    width: 100%;
    height: 100%;
    @include flexColumn;

    @include for-tablet-portrait-up {
        @include flexRow;
    }

    gap: 0;
    align-items: flex-start;
}