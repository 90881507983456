@import './../styles/colors.module.scss';
@import './../styles/mixins.module.scss';

.description {
    color: $blue100;

    a {
        font-size: 16px;
    }
}

.container {
    @include flexColumn;
    overflow: hidden;
    gap: 16px;
}