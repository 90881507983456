@import './../../styles/colors.module.scss';
@import './../../styles/mixins.module.scss';

@keyframes blink {
    0% { opacity: 0 }
    100% { opacity: 1 }
};

.title {
    color: $blue100;
    font-size: 36px;
    font-family: 'Comic Neue';
    margin: 0;

    @include for-small-phone {
        font-size: 30px;
    }

    @include for-phone-only {
        font-size: 36px;
    }

    @include for-tablet-portrait-up {
        font-size: 48px;
    }

    @include for-tablet-landscape-up {
        font-size: 64px;
    }
}

.cursor {
    color: $orange100;
    animation: unset;

    &.hasAnimation {
        animation: blink 1s cubic-bezier(1,-1, 0, 2) infinite;
    }
}