@import './../styles/colors.module.scss';
@import './../styles/mixins.module.scss';

.container {
    @include container();
    margin: 0 auto;
}

.description {
    color: $beige800;
    font-size: 20px;

    @include for-small-phone {
        font-size: 14px;
    }

    @include for-phone-only {
        font-size: 16px;
    }

    @include for-tablet-portrait-up {
        font-size: 18px;
    }
    @include for-tablet-landscape-up {
        font-size: 20px;
    }
}

.wrapper {
    @include flexColumn;
    justify-content: space-between;

    @include for-tablet-landscape-up {
        @include flexRow;
    }

    @include for-phone-only {
        margin-top: 80px;
    }
    @include for-small-phone {
        margin-top: 80px;
    }
}

.textContainer {
    @include flexColumn;
    align-items: flex-start;
    gap: 20px;
}

.avatarContainer {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    -webkit-box-pack: center;

    @include for-tablet-landscape-up {
        width: 50%;
    }
}