@import './../styles/colors.module.scss';
@import './../styles/variables.module.scss';
@import './../styles/mixins.module.scss';

.wrapper {
    position: relative;
    height: auto;
    transform: translateZ(0);
    background-color: $beige100;
}

.about {
    z-index: 5;
    @include for-phone-only {
        z-index: unset;
        box-shadow: none;
    }
    @include for-small-phone {
        z-index: unset;
        box-shadow: none;
    }
    box-shadow: $baseTopShadow;
}

.work {
    z-index: 3;
    @include for-phone-only {
        z-index: unset;
        box-shadow: none;
    }
    @include for-small-phone {
        z-index: unset;
        box-shadow: none;
    }
    box-shadow: $baseBottomShadow;
}

.contact {
    z-index: 1;
    @include for-phone-only {
        z-index: unset;
    }
    @include for-small-phone {
        z-index: unset;
    }
}