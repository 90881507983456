@import "@fontsource/baloo-2/500.css";
@import "@fontsource/comic-neue/700.css";
@import "@fontsource/indie-flower/400.css";
@import "./styles/colors.module.scss";

html, body {
  background-color: $beige100;
  margin: 0;
  font-family: 'Baloo 2',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  p {
    margin: 0;
  }
  scroll-behavior: smooth;
}

*, ::before, ::after {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
