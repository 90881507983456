@import './breakpoints.module.scss';

@mixin for-small-phone {
    @media (max-width: 479px) { @content; }
}

@mixin for-phone-only {
    @media (min-width: $sm) and (max-width: 767px) { @content; }
}
@mixin for-tablet-portrait-up {
    @media (min-width: $md) { @content; }
}
@mixin for-tablet-landscape-up {
    @media (min-width: $lg) { @content; }
}
@mixin for-desktop-up {
    @media (min-width: $xl) { @content; }
}

@mixin flexRow {
    display: flex;
    flex-direction: row;
    align-items: center;
}

@mixin flexColumn {
    display: flex;
    flex-direction: column;
    align-items: center;
}

@mixin container {
    max-width: 1440px;
    padding-inline-start: 40px;
    padding-inline-end: 40px;
    padding-top: 40px;
    padding-bottom: 40px;

    @include for-small-phone {
        padding-inline-start: 24px;
        padding-inline-end: 24px;
    }

    @include for-phone-only {
        padding-inline-start: 24px;
        padding-inline-end: 24px;
    }

    @include for-tablet-portrait-up {
        padding-inline-start: 32px;
        padding-inline-end: 32px;
    }

    @include for-tablet-landscape-up {
        padding-inline-start: 40px;
        padding-inline-end: 40px;
    }
}