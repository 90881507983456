@import './../../../styles/colors.module.scss';
@import './../../../styles/mixins.module.scss';

.wrapper {
    background-color: $blue100;
    width: 100%;
    height: 60px;
    @include flexColumn;
    justify-content: center;

    @include for-tablet-portrait-up {
        width: 30%;
        height: 100%; 
    }

    @include for-desktop-up {
        width: 50%;
    }
}

.title {
    color: $beige100;
    font-family: 'Indie Flower';
    font-size: 40px;
    display: flex;

    @include for-tablet-portrait-up {
        font-size: 64px;
        display: block;
    }
}

.webLinksContainer {
    color: $beige100;
    position: absolute;
    bottom: 20px;
    display: none;

    @include for-tablet-portrait-up {
        display: block;
    }
}