@import './../../styles/colors.module.scss';

.link {
    position: relative;
    font-size: 18px;
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
    &:focus {
      box-shadow: none;
    };
    display: inline-block;

    &.default {
        color: currentColor;
        &:hover {
          &::before {
            opacity: 1;
            transform: translate3d(0,3px,0);
          };
        };
        &::before {
          opacity: 0;
          content: '';
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 3px;
          border-radius: 12px;
          background-color: $orange100;
          transition: all .2s cubic-bezier(.17,.9,.3,1.2);
          transform: translate3d(0,15px,0);
        };
    }
    &.work {
        color: $blue100;
        padding: 0 4px;
        &:hover {
          &::before {
            width: 100%;
          };
        };
        &::before {
          content: '';
          position: absolute;
          left: 0;
          bottom: 0;
          width: 20px;
          height: 3px;
          border-radius: 12px;
          background-color: $orange100;
          transition: all .3s cubic-bezier(.4,0,0,1);
        };
    }
    &.about {
        box-shadow: inset 0 -3px 0 0 #FF9F45;
      &:hover {
        box-shadow: inset 0 -100px 0 0 #FF9F45;
        color: white;
      };
      &:focus {
        box-shadow: inset 0 -100px 0 0 #FF9F45;
        color: white;
      };
      color: $blue100;
      padding: 0 4px;
      margin: 0 -3px;
      transition: all .4s cubic-bezier(.4,0,0,1);
    }
}