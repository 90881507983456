@import './../../../styles/mixins.module.scss';
@import './../../../styles/colors.module.scss';

.wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
    width: 100%;
    background-color: $beige100;
    @include for-tablet-portrait-up {
        align-items: center;
        width: 70%;
    }
    @include for-desktop-up {
        width: 50%;
    }
    @include container();
}