@import './../../styles/colors.module.scss';

@keyframes jump {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-20px);
    }
    60% {
        transform: translateY(-10px);
    }
}

.container {
    svg {
        height: 100%;
        width: 100%;
    }
    color: $blue100;
    height: 40px;
    width: 40px;
    position: absolute;
    bottom: 3%;
    right: calc(50% - 20px);
    animation: jump 2s infinite;
}